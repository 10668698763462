import React, { useEffect, useState } from "react";
// import LoginForm from '../../components/LoginForm'
import { ClockCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import DOMPurify from "dompurify";
import moment from "moment";

export const Title = (props) => {
  return (
    <>
      <h1
        style={{
          ...props?.style,
        }}
      >
        {props?.scholarshipInfo?.title || "No NAme"}
      </h1>
      <h4
        style={{
          ...props?.style,
        }}
      >
        <ClockCircleOutlined />{" "}
        {moment(
          props?.scholarshipInfo?.recurrenceConfig?.start ?? new Date()
        ).format("MMMM DD, YYYY")}{" "}
        -{" "}
        {moment(
          props?.scholarshipInfo?.recurrenceConfig?.deadline ?? new Date()
        ).format("MMMM DD, YYYY")}
      </h4>
    </>
  );
};

export const DontWait = (props) => {
  return (
    <>
      <h1 className="" style={{ marginBottom: "-7px", ...props?.style }}>
        {props?.text}
      </h1>
      <p
        className=""
        style={{
          ...props?.style,
        }}
      >
        {props?.subText}
      </p>
    </>
  );
};

export const Header = (props) => {
  return (
    <>
      <div
        style={{
          borderBottomStyle: "double",
          borderWidth: "1px",
          borderColor: "lightgray",
        }}
      >
        <img
          src="/new/icon.png"
          style={{ width: "200px", padding: "10px 5px 5px 20px" }}
        />
      </div>
    </>
  );
};

export const Passage = (props) => {
  return (
    <>
      <div>
        <p
          style={{
            ...props?.style,
          }}
        >
          {props?.text}
        </p>
      </div>
    </>
  );
};

export const Award = (props) => {
  return (
    <>
      <div>
        <h3
          style={{
            ...props?.style,
          }}
        >
          Awards
        </h3>
        <h3
          style={{
            ...props?.style,
          }}
        >
          {" "}
          ${props?.scholarshipInfo?.awards}:
        </h3>
      </div>
    </>
  );
};

export const Description = (props) => {
  return (
    <>
      <div>
        <h3
          style={{
            ...props?.style,
          }}
        >
          Scholarship description
        </h3>
        <div
          className={props?.className}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(props?.scholarshipInfo?.description),
          }}
        />
      </div>
    </>
  );
};
