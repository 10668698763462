import { API_PATH } from "../constants/common.constant";
import fetch_ from "../interceptors/FetchInterceptor";

const { GET_PUBLIC_URL } = API_PATH;

export const CommonService_ = {
  getPublicLink: (data) => {
    return fetch_({
      url: GET_PUBLIC_URL,
      method: "GET",
      params: data,
    });
  },
};
