import React, { useEffect, useState } from "react";
// import LoginForm from '../../components/LoginForm'
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { ClockCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Loading from "components/shared-components/Loading";
import { ScholarshipService_ } from "services/scholarship.service";
import { SUBDOMAIN } from "configs/AppConfig";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import DOMPurify from "dompurify";
import ScholarshipFields from "./scolarship-fields";
import Theme1 from "./Theme1.js";
import Theme2 from "./Theme2.js";
import Theme3 from "./Theme3.js";
import useAxios from "axios-hooks";
import ScholarshipCountDown from "./scholarship-countdown";
import { importedData1 } from "./data.js";
import fetch_ from "../../../interceptors/FetchInterceptor";

const backgroundURL = "/img/others/img-17.jpg";
const backgroundStyle = {
  backgroundImage: `url(${backgroundURL})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const ScholarshipApplication = (props) => {
  const theme = useSelector((state) => state.theme.currentTheme);
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isInvalidSlug, setIsInvalidSlug] = useState(true);
  const [scholarshipInfo, setScolarshipInfo] = useState(null);
  const [frameData, setFrameData] = useState(null);
  //const [scholarshipInfo, setScolarshipInfo] = useState(importedData1);

  useEffect(() => {
    ScholarshipService_.getScholarship(SUBDOMAIN)
      .then(async (data) => {
        setIsInvalidSlug(!data?.data);
        setScolarshipInfo(data?.data);
        fetch_({
          url: `/scholarships/iframe/${data?.data?.id}`,
          method: "GET",
        }).then((data) => {
          setFrameData(data?.data);
        });
      })
      .catch((e) => setIsInvalidSlug(true))
      .finally(() => setIsLoading(false));
  }, []);

  // useEffect(() => {

  //   fetch_({
  //       url: `/scholarships/3472f637-ca61-4323-8908-d46003fb9623`,
  //       method: "GET"
  //     })
  //     .then(async(data) => {
  //       setIsInvalidSlug(!data?.data);
  //       setScolarshipInfo(data?.data);
  //         fetch_({
  //           url: `/scholarships/iframe/${data?.data?.id}`,
  //           method: "GET"
  //         })
  //         .then((data) => {
  //           setFrameData(data?.data);
  //         })
  //     })
  //     .catch((e) => setIsInvalidSlug(true))
  //     .finally(() => setIsLoading(false));
  // }, []);

  // useEffect(() => {
  //   const [{ data: frameData }] = useAxios(
  //     { url: `/scholarships/iframe/${scholarshipInfo.id}` },
  //     { useCache: false, manual: false }
  //   );
  // }, [scholarshipInfo]);

  if (!isLoading && isInvalidSlug) {
    return (
      <div className={`loading text-center cover-page`}>
        <h1>
          <Text type="danger">
            <CloseCircleOutlined /> Invalid Scolarship{" "}
          </Text>
        </h1>
      </div>
    );
  }

  const renderRightScreen = () => {
    if (moment(scholarshipInfo?.recurrenceConfig?.start).isAfter()) {
      return (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col xs={0} sm={0} md={0} lg={20}>
            <h1 className="text-white">
              This scholarship hasn’t been launched yet. We’ll remind you when
              it's open for applications.
            </h1>
            <p className="text-white">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </Col>
        </Row>
      );
    } else if (moment(scholarshipInfo?.recurrenceConfig?.deadline).isBefore()) {
      return (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col xs={0} sm={0} md={0} lg={20}>
            <h1 className="text-white">This scholarship has been closed.</h1>
            <p className="text-white">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row
          justify="center"
          align="middle"
          style={{ minHeight: "100vh", padding: 25 }}
        >
          <Col xs={0} sm={0} md={0} lg={24} className="text-center">
            <h1 className="text-white" style={{ marginTop: 80 }}>
              Don't wait, apply noooow!
            </h1>
            <p className="text-white">We'll stop accepting applications in:</p>
            <ScholarshipCountDown
              scholarshipInfo={scholarshipInfo}
              timeFinished={() => setScolarshipInfo(scholarshipInfo)}
            />
          </Col>
          <Col xs={0} sm={0} md={0} lg={24}>
            <ScholarshipFields scholarshipInfo={scholarshipInfo} />
          </Col>
        </Row>
      );
    }
  };
  console.log(frameData);

  return isLoading ? (
    <Loading cover="page" />
  ) : frameData?.theme === "THEME1" ? (
    <Theme1
      scholarshipInfo={scholarshipInfo}
      setScolarshipInfo={setScolarshipInfo}
    />
  ) : frameData?.theme === "THEME2" ? (
    <Theme2
      scholarshipInfo={scholarshipInfo}
      setScolarshipInfo={setScolarshipInfo}
    />
  ) : (
    <Theme3
      scholarshipInfo={scholarshipInfo}
      setScolarshipInfo={setScolarshipInfo}
    />
  );
};

export default ScholarshipApplication;
