import {
  SIDE_NAV_LIGHT,
  DIR_LTR,
  NAV_TYPE_TOP,
  NAV_TYPE_SIDE,
} from "constants/ThemeConstant";
import { env, subdomain, isSubdomain } from "./EnvironmentConfig";

export const APP_NAME = env.APP_NAME;
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const API_UPLOAD_URL = `${API_BASE_URL}api/v1/common/upload`;
export const FRONTEND_DOMAIN = env.FRONTEND_DOMAIN;

export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";

export const SUBDOMAIN = subdomain;
export const IS_SUBDOMAIN = isSubdomain;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#fff",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};
