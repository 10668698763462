import React, { useEffect, useState } from "react";
import { Checkbox, DatePicker, Form, Select, Upload, notification } from "antd";
import { ScholarshipService_ } from "services/scholarship.service";
import { useForm } from "antd/lib/form/Form";
import { Input, InputNumber, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { API_UPLOAD_URL } from "configs/AppConfig";
import { beforeUpload } from "utils/utilities";
import { PlusOutlined } from "@ant-design/icons";
import { useForceUpdate } from "hooks/useForceUpdate";
import { cloneDeep } from "lodash";
import moment from "moment";

const ScholarshipInput = ({ scholarshipInfo, buttonStyle, termsStyle }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [ageField, setAgeField] = useState(null);
  const [form] = useForm();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (scholarshipInfo?.fields) {
      scholarshipInfo?.fields?.forEach((scholarshipField) => {
        if (scholarshipField?.field?.key === "age")
          setAgeField(scholarshipField);
      });
    }
  }, [scholarshipInfo]);

  const submitApplication = () => {
    setIsLoading(true);
    form.validateFields().then((data) => {
      console.log(data);
      const { firstName, lastName, ...restData } = data;

      // Concatenate first and last names into a single "name" field
      const fullName = `${firstName} ${lastName}`;

      // Add the concatenated name to the form data
      restData.name = fullName;

      // Add scholarshipId to the form data
      restData.scholarshipId = scholarshipInfo.id;

      ScholarshipService_.submitApplication(scholarshipInfo.id, restData)
        .then((data) => {
          if (data?.data?.id) {
            notification.success({
              description: "Applicant has been successfully submitted",
              message: "Submitted",
            });
            form.resetFields();
          }
        })
        .finally(() => setIsLoading(false));
    });
  };

  function validateAge(value) {
    const eligibilityType = ageField?.attributes?.eligibilityType;
    const eligibilityValue = ageField?.attributes?.eligibilityValue;

    if (!eligibilityType || !eligibilityValue || !value) {
      return true;
    }

    const currentDate = moment();
    const age = currentDate.diff(value, "years");

    // Check if the user is born in the current year but hasn't had their birthday yet
    const hasBirthdayPassed =
      currentDate.month() > value.month() ||
      (currentDate.month() === value.month() &&
        currentDate.date() >= value.date());

    if (!hasBirthdayPassed) {
      age--;
    }

    switch (eligibilityType) {
      case "is":
        return {
          isValid: age?.toString() === eligibilityValue,
          errorMessage: `Age should be exactly ${eligibilityValue}`,
        };
      case "lessthanOrIs":
        return {
          isValid: age <= eligibilityValue,
          errorMessage: `Age should be less than or equal to ${eligibilityValue}`,
        };
      case "greaterthanOrIs":
        return {
          isValid: age >= eligibilityValue,
          errorMessage: `Age should be greater than or equal to ${eligibilityValue}`,
        };
      default:
        return { isValid: true, errorMessage: null };
    }
  }

  function validateDate(value, scholarshipField) {
    const eligibilityType = scholarshipField?.attributes?.eligibilityType;
    const eligibilityValue = scholarshipField?.attributes?.eligibilityValue;

    if (!eligibilityType || !eligibilityValue || !value) {
      return true;
    }

    const fieldName = scholarshipField?.field?.name;
    switch (eligibilityType) {
      case "between":
        return {
          isValid: moment(value).isBetween(
            moment(eligibilityValue[0], "DD/MM/YYYY"),
            moment(eligibilityValue[1], "DD/MM/YYYY")
          ),
          errorMessage: `${fieldName} should be between ${moment(
            eligibilityValue[0],
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")} and ${moment(
            eligibilityValue[1],
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")}`,
        };
      case "before":
        return {
          isValid: moment(value).isBefore(
            moment(eligibilityValue, "DD/MM/YYYY")
          ),
          errorMessage: `${fieldName} should be before ${moment(
            eligibilityValue,
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")}`,
        };
      case "after":
        return {
          isValid: moment(value).isAfter(
            moment(eligibilityValue, "DD/MM/YYYY")
          ),
          errorMessage: `${fieldName} should be after ${moment(
            eligibilityValue,
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")}`,
        };
      default:
        return { isValid: true, errorMessage: null };
    }
  }

  function validateOption(value, scholarshipField) {
    const eligibilityType = scholarshipField?.attributes?.eligibilityType;
    const eligibilityValue = scholarshipField?.attributes?.eligibilityValue;

    if (!eligibilityValue || !value) {
      return true; // no eligibilityValue provided, always valid
    }
    let validValues = []; // eligibility Value  in terms of keys
    let matchValues = []; // eligibility Value  in terms of options value
    if (Array.isArray(eligibilityValue)) {
      validValues = eligibilityValue;
    } else {
      validValues = eligibilityValue.split(",");
    }
    matchValues = validValues.map(
      (key) => scholarshipField?.field.options[key]
    );

    const strValues = matchValues.join(", ");
    const fieldName = scholarshipField?.field?.name;

    const isValid = validValues.includes(value); // check if the selected value is in the eligible values array
    switch (eligibilityType) {
      case "is":
        return {
          isValid,
          errorMessage: `${fieldName} should equal to ${strValues}`,
        };
      case "oneOf":
        return {
          isValid,
          errorMessage: `${fieldName} should one of  ${strValues}`,
        };
      case "not":
        return {
          isValid: !isValid,
          errorMessage: `${fieldName} should one of  ${strValues}`,
        };
      case "notOneOf":
        return {
          isValid: !isValid,
          errorMessage: `${fieldName} should be different from ${strValues} `,
        };
      default:
        return { isValid: true, errorMessage: null }; // unsupported eligibilityType
    }
  }

  const getDynamicFields = () => {
    const allFieldExceptAge = scholarshipInfo?.fields?.filter(
      (field) => field.field.key !== "age"
    );
    return [
      ...(allFieldExceptAge ?? []),
      ...(scholarshipInfo?.requirements ?? []),
    ].map((scholarshipField, i) => prepareFieldOptions(scholarshipField, i));
  };

  // const handleChange = (info, namePath) => {
  //   const fieldValue = form.getFieldValue(namePath[0]);
  //   if (info?.file?.status === "removed") {
  //     fieldValue[namePath[1]][namePath[2]] = null;
  //     form.setFieldsValue({
  //       [namePath[0]]: fieldValue,
  //     });
  //   } else if (info?.file?.status === "done") {
  //     const data = cloneDeep(info?.file?.response?.data ?? {});
  //     data.publicUrl = null;
  //     fieldValue[namePath[1]][namePath[2]] = JSON.stringify(data);
  //     form.setFieldsValue({
  //       [namePath[0]]: fieldValue,
  //     });
  //   }
  //   forceUpdate();
  // };
  const handleChange = (info, namePath) => {
    const fieldValue = form.getFieldValue(namePath[0]);

    if (info?.file?.status === "removed") {
      fieldValue[namePath[1]][namePath[2]] = null;
    } else if (info?.file?.status === "done") {
      const responseData = info?.file?.response?.data;
      if (responseData) {
        const { publicUrl } = responseData;
        fieldValue[namePath[1]][namePath[2]] = publicUrl;
      }
    } else if (info?.file?.status === "error") {
      console.error("File upload failed:", info.file.error);
    }

    // Update form field value
    form.setFieldsValue({
      [namePath[0]]: fieldValue,
    });

    forceUpdate();
  };

  const getUploadIcon = (namePath) => {
    return form.getFieldValue(namePath) ? null : (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
  };

  const prepareFieldOptions = (scholarshipField, i) => {
    const field_ = scholarshipField?.field;
    const isRequirement = field_?.type === "requirement";
    let fieldName = isRequirement ? scholarshipField?.title : field_?.name;
    fieldName = fieldName ?? field_?.name;
    switch (field_?.dataType) {
      case "option":
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
                {
                  validator: (rule, value, callback) => {
                    const validateResult = validateOption(
                      value,
                      scholarshipField
                    );
                    callback(
                      !validateResult?.isValid
                        ? validateResult?.errorMessage
                        : undefined
                    );
                  },
                },
              ]}
            >
              <Select
                options={Object.keys(field_.options ?? {}).map((key) => ({
                  label: field_.options[key],
                  value: key,
                }))}
              />
            </Form.Item>
          </span>
        );

      case "date":
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
                {
                  validator: (rule, value, callback) => {
                    const validateResult = validateDate(
                      value,
                      scholarshipField
                    );
                    callback(
                      !validateResult?.isValid
                        ? validateResult?.errorMessage
                        : undefined
                    );
                  },
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </span>
        );
      case "editor":
      case "textarea":
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
              ]}
            >
              <TextArea />
            </Form.Item>
          </span>
        );
      case "file": {
        const namePath = ["applicantFields", i, "fieldValue"];
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={namePath}
              label={fieldName}
              rules={[
                {
                  required: scholarshipField?.attributes?.optional === false,
                  message: `Please select ${fieldName}`,
                },
              ]}
            >
              <Upload
                name="file"
                listType="picture-card"
                action={API_UPLOAD_URL}
                onChange={(info) => handleChange(info, namePath)}
                onPreview={(file) => {
                  window.open(
                    file?.response?.data?.publicUrl,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                {getUploadIcon(namePath)}
              </Upload>
            </Form.Item>
          </span>
        );
      }
      case "checkbox":
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
              ]}
            >
              <Checkbox.Group
                options={Object.keys(field_.options ?? {}).map((key) => ({
                  label: field_.options[key],
                  value: key,
                }))}
              />
            </Form.Item>
          </span>
        );

      case "link":
      case "email":
      case "phone":
      case "number":
      default:
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
                {
                  type: ["email", "number"].includes(field_?.dataType)
                    ? field_?.dataType
                    : "string",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </span>
        );
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={submitApplication}>
        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            name="email"
            label="Email"
            rules={[{ type: "email", required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            name="phone"
            label="Phone"
            rules={[{ min: 10, max: 13, required: false }]}
          >
            <Input placeholder="91-9999999999" />
          </Form.Item>
          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            name="dob"
            label="Date of Birth"
            rules={[
              { required: true },
              {
                validator: (rule, value, callback) => {
                  const validateResult = validateAge(value);
                  callback(
                    !validateResult?.isValid
                      ? validateResult?.errorMessage
                      : undefined
                  );
                },
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          {/* <Form.Item style={{paddingLeft: "10px", paddingRight: "10px"}} name="bio" label="Introduction">
                    <Input.TextArea />
                </Form.Item> */}

          {getDynamicFields()}
        </div>
        <span
          style={{
            ...termsStyle,
          }}
        >
          <Checkbox />
          <span
            style={{
              paddingLeft: "5px",
            }}
          >
            I agree to the{" "}
            <a href="https://lenny.ai/terms" target="_blank">
              Terms of use{" "}
            </a>{" "}
            and{" "}
            <a href="https://lenny.ai/privacy" target="_blank">
              {" "}
              Privacy policy{" "}
            </a>
          </span>
        </span>
        <Form.Item
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "20px",
            ...buttonStyle,
          }}
        >
          <Button type="primary" htmlType="submit" style={{ width: "auto" }}>
            Send Application
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ScholarshipInput;
