import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import {
  DashboardOutlined,
  UserOutlined,
  BookOutlined,
} from "@ant-design/icons";

export const NAV_PATH = {
  login: `${AUTH_PREFIX_PATH}/login`,
  dashboard: `${APP_PREFIX_PATH}/dashboard`,
  personal: `${APP_PREFIX_PATH}/personal`,
  scholarships: {
    detail: (scholarshipId) =>
      `${APP_PREFIX_PATH}/scholarships/detail/${scholarshipId}`,
    root: `${APP_PREFIX_PATH}/scholarships`,
    wizard: `${APP_PREFIX_PATH}/scholarships/wizard`,
    template: `${APP_PREFIX_PATH}/scholarships/wizard/template`,
    applicant_detail: (id) =>
      `${APP_PREFIX_PATH}/scholarships/applicant_detail/${id}`,
    winners_list: (id) => `${APP_PREFIX_PATH}/scholarships/winners_list/${id}`,
    rank_criteria: (id) =>
      `${APP_PREFIX_PATH}/scholarships/rank_criteria/${id}`,
    edit: (id) => `${APP_PREFIX_PATH}/scholarships/edit/${id}`,
    targetAudience: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/target_audience`,
    dataCapturing: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/capturing`,
    deadline: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/deadline`,
    attributes: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/attributes`,
    budget: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/budget`,
    requirements: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/requirements`,
    escrow: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/escrow`,
    publish: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/publish`,
  },
  applicants: {
    detail: (scholarshipId, applicantId) =>
      `${APP_PREFIX_PATH}/scholarships/${scholarshipId}/applicants/${applicantId}`,
  },
};

/*Supper User menu start*/
const userNav = [
  // {
  //   key: "Dashboard",
  //   path: `${NAV_PATH.dashboard}`,
  //   title: " Dashboard",
  //   submenu: [],
  //   icon: DashboardOutlined,
  // },
  {
    key: "Personal",
    path: `${NAV_PATH.personal}`,
    title: " Personal",
    submenu: [],
    icon: UserOutlined,
  },
  {
    key: "Scholarships",
    path: `${NAV_PATH.scholarships.root}`,
    title: " Scholarships",
    submenu: [],
    icon: BookOutlined,
  },
];

const navigationConfig = [...userNav];

export const navigationConfigMenu = () => {
  const navigationConfig = [];
  navigationConfig.push(...userNav);
  return navigationConfig;
};

export default navigationConfig;
