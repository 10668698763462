import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/register`}
          component={lazy(() => import(`./authentication/register-2`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/error-1`}
          component={lazy(() => import(`./errors/error-page-1`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/error-2`}
          component={lazy(() => import(`./errors/error-page-2`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/applicants`}
          component={lazy(() => import(`views/app-views/applicants`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/applicants_review`}
          component={lazy(() => import(`views/app-views/applicants/review`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/rank-criteria`}
          component={lazy(() => import(`views/app-views/rank-criteria`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/winner`}
          component={lazy(() => import(`views/app-views/winner`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/terms`}
          component={lazy(() => import(`./terms`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/privacy`}
          component={lazy(() => import(`./privacy`))}
        />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`/homepage`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
